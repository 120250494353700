export class HrConfirmListModel {
    id: number;
    profileId: number;
    checkId: number;
    checkTypeId: number;
    checkName: string;
    hrConfirmReqStatusTypeId: number;
    hrconfirmReqStatusType: string;
    hrConfirmTypeId: number;
    hrConfirmType: string;
    hrconfirmSubTypeId: number;
    hrConfirmSubType: string;
    remarks: string;
    checkStatusTypeId: number;
    isChange: boolean;
    perfomedWfActionId: number;
    extraPrice: number
}

export class HrConfirmListModelResponse {
    list: HrConfirmListModel[];
    totalRecords: number;

}
export class HrConformationListRequest {
    pageNo: number;
    pageSize: number;
    skip: string;
    sortOn: string;
    sortOrder: string;
    profileNumber: string;
    caseId: string;
    fromDate: string;
    toDate: string;
    hrConfirmTypes: number[]
}

export class HrconfirmReqLogsModel {
    id: number;
    hrconfirmReqId: number;
    hrconfirmReqStatusTypeId: number;
    hrConfirmRequestStatusTypeName: string;
    remarks: string;
    createdBy: string;
    createdDate: Date;
}

export class UploadFileResponseModel {
    id: number;
    fileName: string;
    filePath: string;
    uploadedByName: string;
    isMyFile: boolean;
}
export class ChangeHrConformationStatusRequest {
    id: number;
    profileId: number;
    remarks: string;
    hrConfirmRequestStatusTypeId: number;
    checkStatusTypeId: number;
}

export interface IInsuffDashboardResponse {
    insuffCount: number;
    clarificationCount: number;
    approvalCount: number;
    otherCount: number;
}

export interface IProfileCountResponse {
    createdDate: Date;
    count: number;
}

export interface IFinalreportTypeCount {
    finalRptColorId: number;
    count: number;
}

export class ChartCountRequest{
    fromDate: Date;
    toDate:Date
    constructor(init?: Partial<ChartCountRequest>) {
        Object.assign(this, init);
    }
}

